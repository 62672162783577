import React from 'react';
import { Tabs } from 'components';
import { Actions } from 'components/Form/IGPostsFormActions';

const FormActions = props => (
  <Tabs
    border="bottom"
    className="form-actions fieldset"
    content={[
      {
        tabName: 'Actions',
        tabContent: (
          <React.Fragment>
            <Actions {...props} />
          </React.Fragment>
        ),
      },
    ]}
    justify={false}
  />
);

FormActions.propTypes = {};

export default FormActions;
