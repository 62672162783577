import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Button from 'components/Button';

import SingleFieldItems from './SingleFieldItems';
import MultipleFieldItems from './MultipleFieldItems';

import './ListField.scss';

const onDragEnd = swap => result => {
  if (!result.destination) return;

  swap(result.source.index, result.destination.index);
};

function ListField({
  component: Component,
  label,
  name,
  singleField,
  ...props
}) {
  return (
    <FieldArray
      name={name}
      render={({ form, push, remove, swap }) => {
        const values = getIn(form.values, name, []);
        return (
          <DragDropContext onDragEnd={onDragEnd(swap)}>
            <Droppable droppableId="droppable">
              {provided => (
                <div ref={provided.innerRef} className="dnd">
                  <div className="list-field">
                    <SingleFieldItems
                      visible={singleField}
                      Component={Component}
                      name={name}
                      values={values}
                      remove={remove}
                      {...props}
                    />
                    <MultipleFieldItems
                      visible={!singleField}
                      Component={Component}
                      name={name}
                      values={values}
                      remove={remove}
                      {...props}
                    />
                    <Button
                      className="btn-add-field"
                      color="primary"
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      onClick={() => {
                        push({});
                        document.activeElement.blur();
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        );
      }}
      {...props}
    />
  );
}

ListField.propTypes = {
  component: PropTypes.func.isRequired,
  lineItem: PropTypes.string,
  name: PropTypes.string.isRequired,
  singleField: PropTypes.bool,
  sortable: PropTypes.bool,
};

ListField.defaultProps = {
  lineItem: 'Item',
  singleField: false,
  sortable: false,
};

export default ListField;
