import React from 'react';
import { PageToolbar } from 'components';
import { PageThumbnail } from 'components/Stories';
import { Fieldset } from 'components/Form';
import Button from 'components/Button';
import { ButtonGroup, Col, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import './AddPage.scss';
import variables from 'scss/1-settings/colors.scss';
// import PropTypes from 'prop-types';

const reactSelectTheme = theme => ({
  ...theme,
  borderRadius: '0.2rem',
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const search = (
  <React.Fragment>
    <Input
      className="search-input input-icon-right"
      name="tableSearch"
      placeholder="eg. Ad or Sponsor Name"
      type="search"
    />
    <Button
      type="button"
      className="input-group-append"
      icon={<FontAwesomeIcon icon={faSearch} />}
    />
  </React.Fragment>
);
const filters = (
  <Row>
    <Col xs={12} md className="filter">
      <Select
        classNamePrefix="rs"
        defaultValue={1}
        name="sortBy"
        openMenuOnFocus
        options={[
          { label: 'Date Added', value: 1 },
          { label: 'Date Modified', value: 2 },
          { label: 'Name', value: 3 },
        ]}
        theme={reactSelectTheme}
        value={1}
      />
    </Col>
    <Col xs={12} md className="filter">
      <ButtonGroup className="justified">
        <Button type="button" color="primary" size="sm">
          All
        </Button>
        <Button type="button" color="primary" outline size="sm">
          Images
        </Button>
        <Button type="button" color="primary" outline size="sm">
          Videos
        </Button>
      </ButtonGroup>
    </Col>
  </Row>
);

const AddPage = () => (
  <Fieldset legend="Add a Page" className="add-page">
    <PageToolbar search={search} filters={filters} growSearch />
    <Row>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
      <Col xs={6} sm={4} md={3} lg={2}>
        <PageThumbnail imgSrc="https://placedog.net/180/320" name="Page name" />
      </Col>
    </Row>
  </Fieldset>
);

AddPage.propTypes = {};

export default AddPage;
