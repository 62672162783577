import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import renderDropZoneField from 'components/Form/DropZoneField';
import './DropZone.scss';

/** A component for uploading file(s) */

const defaultEmptyMessage = 'Drop a file here or click to upload';

const wrapValue = value => {
  if (!value) return value;

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
};

const DropZone = props => {
  const {
    className,
    fieldProps,
    emptyMessage,
    maxSize,
    multiple,
    name,
    onChange,
  } = props;
  return (
    <Field
      name={name}
      render={({ form, field }) =>
        renderDropZoneField({
          className,
          emptyMessage,
          fieldProps,
          input: {
            ...field,
            value: wrapValue(field.value, multiple),
            onChange: files => {
              if (multiple || !files || !Array.isArray(files)) {
                // form.setFieldValue(name, files);
                onChange(files);
                return;
              }
              const [file] = files;

              form.setFieldValue(name, file);
              onChange(file);
            },
          },
          maxSize,
          multiple,
        })
      }
    />
  );
};

DropZone.propTypes = {
  /** Custom style class */
  className: PropTypes.string,
  /** Empty message */
  emptyMessage: PropTypes.node,
  /** Props to be passed directly to Dropzone component */
  fieldProps: PropTypes.shape({}),
  /** Maximum individual file size in bytes */
  maxSize: PropTypes.number,
  /** Can upload more than one file */
  multiple: PropTypes.bool,
  /** Input name & ID */
  name: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
};

DropZone.defaultProps = {
  className: '',
  emptyMessage: defaultEmptyMessage,
  fieldProps: {
    accept: 'image/png, image/jpeg',
  },
  maxSize: 64000000,
  multiple: false,
  name: '',
  onChange: () => {},
};

export default DropZone;
