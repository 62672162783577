import { normalize } from 'normalizr';
import { has } from 'lodash';

export const requiresNormalizing = action => has(action, 'meta.schema');

export default () => next => action => {
  if (action.type === 'API_SUCCESS' && requiresNormalizing(action)) {
    if (!action.payload) {
      // treat this as a DELETE
      next({
        type: 'REMOVE_ENTITY',
        payload: { path: [action.meta.schema.key, action.meta.id] },
      });

      return next(action);
    }

    const normalized = normalize(
      action.payload.items || action.payload,
      action.meta.schema
    );

    return next({
      ...action,
      payload: {
        ...action.payload,
        normalized,
      },
    });
  }

  return next(action);
};
