import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'components';
import './Tabs.scss';

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    const { setActiveTab } = this.props;
    this.state = {
      activeTab: setActiveTab,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const { setupNext } = this.props;
    if (setupNext) {
      setupNext(() =>
        this.setState(prevState => ({
          activeTab: prevState.activeTab + 1,
        }))
      );
    }
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const {
      border,
      className,
      content,
      justify,
      numbered,
      showButtons,
      stickyNav,
      submitButton,
      hideNext,
    } = this.props;
    const borderClass = {
      default: '',
      bottom: 'bordered-bottom',
      top: 'bordered-top',
    };

    return (
      <div
        className={classnames(
          'tabs',
          { 'nav-justified': justify },
          borderClass[border],
          className
        )}
      >
        <div className="tabs-wrap">
          <Nav
            tabs
            className={classnames({
              'sticky-nav': stickyNav,
              numbered,
            })}
          >
            {content.map((item, index) => (
              <NavItem key={`${item.tabName + index}.link`}>
                <NavLink
                  className={classnames({
                    active: activeTab === index,
                  })}
                  onClick={() => {
                    this.toggle(index);
                  }}
                  tag="button"
                  type="button"
                >
                  {numbered && <div className="tab-number">{index + 1}</div>}
                  {item.tabName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {content.map((item, index) => (
              <TabPane
                className={item.tabName
                  .replace(/[^a-zA-Z]/g, '-')
                  .toLowerCase()}
                key={`${item.tabName + index}.content`}
                tabId={index}
              >
                {item.tabContent}
              </TabPane>
            ))}
          </TabContent>
        </div>
        {showButtons && (
          <div className="tab-buttons">
            <div>
              <Button
                color="secondary"
                disabled={activeTab === 0}
                onClick={() => {
                  this.toggle(activeTab - 1);
                  document.activeElement.blur();
                }}
              >
                Previous
              </Button>
            </div>
            <div>
              {activeTab < content.length - 1 && !hideNext && (
                <Button
                  color="primary"
                  disabled={activeTab === content.length - 1}
                  onClick={() => {
                    this.toggle(activeTab + 1);
                    document.activeElement.blur();
                  }}
                >
                  Next
                </Button>
              )}
              {(activeTab === content.length - 1 || hideNext) && submitButton}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Tabs.propTypes = {
  setActiveTab: PropTypes.number,
  border: PropTypes.oneOf(['default', 'top', 'bottom']),
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({ tabName: PropTypes.string, tabContent: PropTypes.node })
  ).isRequired,
  justify: PropTypes.bool,
  numbered: PropTypes.bool,
  showButtons: PropTypes.bool,
  stickyNav: PropTypes.bool,
  submitButton: PropTypes.node,
  setupNext: PropTypes.func,
  hideNext: PropTypes.bool,
};

Tabs.defaultProps = {
  setActiveTab: 0,
  className: '',
  border: 'top',
  justify: true,
  numbered: false,
  showButtons: false,
  stickyNav: false,
  submitButton: null,
  setupNext: undefined,
  hideNext: false,
};
