import {
  faPlus,
  faCopy,
  faTrashAlt,
  faPencil,
  faExternalLink,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';

const buildActions = (handlers, props) => [
  {
    tooltip: 'New',
    icon: 'add_circle_outline',
    isFreeAction: true,
    onClick: () => handlers.new(props),
    toolbarIcon: faPlus,
    toolbarClassName: 'btn-create',
    toolbarButtonPriority: 'primary',
  },
  () => {
    const {
      api: { copy },
    } = props;

    if (handlers.copy && (copy || handlers.supportsCopy)) {
      return {
        tooltip: 'Duplicate',
        icon: faCopy,
        isRowAction: true,
        onClick: (_, item) => handlers.copy(item, props),
      };
    }

    return null;
  },
  {
    tooltip: 'Edit',
    color: 'success',
    type: 'button',
    icon: faPencil,
    isRowAction: true,
    onClick: (_, item) => handlers.edit(item, props),
  },
  renderItem => {
    if (handlers.delete && handlers.supportsDelete && !renderItem.isPermanent) {
      return {
        tooltip: 'Delete',
        color: 'danger',
        icon: faTrashAlt,
        isRowAction: true,
        isSelectedAction: true,
        onClick: (_, item) => handlers.delete(item, props),
        toolbarIcon: faTrashAlt,
        toolbarClassName: 'btn-delete',
        toolbarButtonPriority: 'danger',
      };
    }

    return null;
  },
  {
    tooltip: '',
    color: 'info',
    type: 'button',
    icon: faChevronDown,
    isRowAction: true,
    onClick: (_, item) => handlers.changeSort(item, props, true),
    isDisabled: item => item.sortOrder === props.sortOrderLimits.max,
  },
  {
    tooltip: '',
    color: 'info',
    type: 'button',
    icon: faChevronUp,
    isRowAction: true,
    onClick: (_, item) => handlers.changeSort(item, props, false),
    isDisabled: item => item.sortOrder === props.sortOrderLimits.min,
  },
  data => {
    if (
      (!data.url && !(handlers.showOpenUrl && handlers.showOpenUrl(data))) ||
      !handlers.openExternal
    )
      return null;

    return {
      tooltip: 'View Online',
      icon: faExternalLink,
      isRowAction: true,
      onClick: (_, item) => handlers.openExternal(item, props),
    };
  },
];

export default (handlers, props, categories) => {
  const sortOrders = categories.map(x => x.sortOrder);
  const sortOrderLimits = {
    min: Math.min(...sortOrders),
    max: Math.max(...sortOrders),
  };
  return buildActions(
    {
      openExternal: item => {
        window.open(item.url);
        document.activeElement.blur();
      },
      ...handlers,
    },
    { ...props, sortOrderLimits }
  );
};
