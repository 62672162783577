import { takeEvery, call } from 'redux-saga/effects';
import { toast } from 'components';

export function* handle500(action) {
  let { message } = action.payload;
  if (
    action.payload.response.error &&
    action.payload.response.error.details &&
    action.payload.response.error.details.includes('DuplicateNameException')
  ) {
    // eslint-disable-next-line prefer-destructuring
    message = action.payload.response.error.message;
  }
  yield call(() => toast.error(message));
}

function* erroListener() {
  yield takeEvery(
    x =>
      (x.type === 'API_FAILURE' && x.payload.status === 500) ||
      (x.type === 'API_REQUEST' && x.error),
    handle500
  );
}

export default erroListener;
