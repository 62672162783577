import React from 'react';
import { camelCase } from 'lodash';
import { Col, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './FormRow.scss';

const FormRow = props => {
  const {
    align,
    borderless,
    children,
    className,
    helpText,
    label,
    name,
    stacked,
    toggleContainer,
  } = props;

  const htmlFor = name || (label && camelCase(label.replace(/\s+/, '')));

  return (
    <div
      className={classnames(
        className,
        styles.formRow,
        'form__form-group',
        { borderless, 'toggle-container': toggleContainer },
        stacked ? 'stacked' : 'horizontal'
      )}
    >
      <Row
        className={classnames('form-row', {
          'align-items-top': align === 'top',
          'align-items-center': align === 'center',
        })}
      >
        <Label
          className="form__form-group-label"
          htmlFor={htmlFor}
          md={stacked ? 12 : 3}
          xs={(toggleContainer && 'auto') || ''}
        >
          {label}{' '}
          {helpText && (
            <small className={classnames('help-text', { 'd-block': !stacked })}>
              {helpText}
            </small>
          )}
        </Label>
        <Col xs={(toggleContainer && 'auto') || 12} md={stacked ? 12 : 9}>
          <div className="input-container">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

FormRow.propTypes = {
  align: PropTypes.oneOf(['top', 'center']),
  borderless: PropTypes.bool,
  children: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  stacked: PropTypes.bool,
  toggleContainer: PropTypes.bool,
};

FormRow.defaultProps = {
  align: 'center',
  borderless: false,
  className: null,
  helpText: '',
  stacked: false,
  toggleContainer: false,
  name: '',
};

export default FormRow;
