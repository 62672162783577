import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ButtonGroup, Col, Row } from 'reactstrap';
import { Button } from 'components';
import './DataTablePagination.scss';

const defaultButton = props => (
  <Button type="button" {...props} className="border" color="light" size="sm" />
);

const RenderPageJumpComponent = props => {
  const {
    onChange,
    value,
    onBlur,
    onKeyPress,
    inputType,
    pageJumpText,
  } = props;
  return (
    <input
      aria-label={pageJumpText}
      className="form-control page-jump"
      onBlur={onBlur}
      onChange={onChange}
      onKeyPress={onKeyPress}
      type={inputType}
      value={value}
    />
  );
};

RenderPageJumpComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  pageJumpText: PropTypes.string.isRequired,
};

const RenderPageSizeOptionsComponent = props => {
  const {
    pageSize,
    pageSizeOptions,
    rowsSelectorText,
    onPageSizeChange,
    rowsText,
  } = props;
  return (
    <select
      className="form-control page-size-options"
      aria-label={rowsSelectorText}
      onChange={e => onPageSizeChange(Number(e.target.value))}
      value={pageSize}
    >
      {pageSizeOptions.map((option, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <option key={i} value={option}>
          {`${option} ${rowsText}`}
        </option>
      ))}
    </select>
  );
};

RenderPageSizeOptionsComponent.propTypes = {
  pageSize: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  rowsSelectorText: PropTypes.string.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  rowsText: PropTypes.string.isRequired,
};

export default class DataTablePagination extends Component {
  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);

    this.state = {
      currentPage: props.page,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { page } = this.props;
    if (page !== nextProps.page) {
      this.setState({ currentPage: nextProps.page });
    }
  }

  getSafePage(currentPage) {
    const { page, pages } = this.props;
    if (Number.isNaN(currentPage)) {
      this.setState({ currentPage: page });
    }
    return Math.min(Math.max(currentPage, 0), pages - 1);
  }

  getPageJumpProperties() {
    const { pageJumpText } = this.props;
    const { currentPage } = this.state;
    return {
      onKeyPress: e => {
        if (e.which === 13 || e.keyCode === 13) {
          this.applyPage();
        }
      },
      onBlur: this.applyPage,
      value: currentPage === '' ? '' : currentPage + 1,
      onChange: e => {
        const val = e.target.value;
        const page = val - 1;
        if (val === '') {
          return this.setState({ currentPage: val });
        }
        return this.setState({ currentPage: this.getSafePage(page) });
      },
      inputType: currentPage === '' ? 'text' : 'number',
      pageJumpText,
    };
  }

  changePage(p) {
    const { onPageChange, page } = this.props;
    const newPage = this.getSafePage(p);
    this.setState({ currentPage: newPage });
    if (page !== p) {
      onPageChange(p);
    }
  }

  applyPage(e) {
    const { page } = this.props;
    const { currentPage } = this.state;
    if (e) {
      e.preventDefault();
    }
    const p = currentPage;
    this.changePage(p === '' ? page : p);
  }

  render() {
    const {
      pages,
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      PreviousComponent,
      NextComponent,
      renderPageJump,
      renderCurrentPage,
      renderTotalPagesCount,
      renderPageSizeOptions,
      style,
      previousText,
      pageText,
      ofText,
      rowsSelectorText,
      rowsText,
      nextText,
    } = this.props;

    return (
      <Row
        className={classnames(
          className,
          'data-table-pagination justify-content-end align-items-center'
        )}
        style={style}
      >
        <Col xs="auto" className="page-settings">
          <Row className="align-items-center">
            <Col xs="auto" className="page-jump-container">
              {pageText}{' '}
              {showPageJump
                ? renderPageJump(this.getPageJumpProperties())
                : renderCurrentPage(page)}{' '}
              {ofText}&nbsp;{renderTotalPagesCount(pages)}
            </Col>
            <Col xs="auto">
              {showPageSizeOptions &&
                renderPageSizeOptions({
                  pageSize,
                  rowsSelectorText,
                  pageSizeOptions,
                  onPageSizeChange,
                  rowsText,
                })}
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <ButtonGroup className="next-prev">
            <PreviousComponent
              className="previous"
              onClick={() => {
                if (!canPrevious) return;
                this.changePage(page - 1);
              }}
              disabled={!canPrevious}
            >
              {previousText}
            </PreviousComponent>

            <NextComponent
              className="next"
              onClick={() => {
                if (!canNext) return;
                this.changePage(page + 1);
              }}
              disabled={!canNext}
            >
              {nextText}
            </NextComponent>
          </ButtonGroup>
        </Col>
      </Row>
    );
  }
}

DataTablePagination.propTypes = {
  canNext: PropTypes.bool,
  canPrevious: PropTypes.bool,
  className: PropTypes.string,
  NextComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  nextText: PropTypes.string,
  ofText: PropTypes.string,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  page: PropTypes.number,
  pageJumpText: PropTypes.string,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pageText: PropTypes.string,
  PreviousComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  previousText: PropTypes.string,
  renderCurrentPage: PropTypes.func,
  renderPageJump: PropTypes.func,
  renderPageSizeOptions: PropTypes.func,
  renderTotalPagesCount: PropTypes.func,
  rowsSelectorText: PropTypes.string,
  rowsText: PropTypes.string,
  showPageJump: PropTypes.bool,
  showPageSizeOptions: PropTypes.bool,
  style: PropTypes.shape(),
};

DataTablePagination.defaultProps = {
  canNext: true,
  canPrevious: false,
  className: '',
  NextComponent: defaultButton,
  nextText: 'Next',
  ofText: 'of',
  onPageChange: null,
  onPageSizeChange: null,
  page: 0,
  pageJumpText: 'Page',
  pages: 0,
  pageSize: 0,
  pageSizeOptions: [5, 10, 20, 50, 100],
  pageText: 'Page',
  PreviousComponent: defaultButton,
  previousText: 'Previous',
  renderCurrentPage: page => <span className="-currentPage">{page + 1}</span>,
  renderPageJump: RenderPageJumpComponent,
  renderPageSizeOptions: RenderPageSizeOptionsComponent,
  renderTotalPagesCount: pages => (
    <span className="-totalPages">{pages || 1}</span>
  ),
  rowsSelectorText: 'rows',
  rowsText: 'rows',
  showPageJump: true,
  showPageSizeOptions: true,
  style: {},
};
