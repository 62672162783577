import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const PostsList = Loadable({
  loader: () => import('./containers/PostListPage'),
  loading: () => <Loading visible />,
});

export const List = Loadable({
  loader: () => import('./containers/ListPage'),
  loading: () => <Loading visible />,
});

export const NewPost = Loadable({
  loader: () => import('./containers/AddPostForAccountPage'),
  loading: () => <Loading visible />,
});

export const NewCustomPost = Loadable({
  loader: () => import('./containers/AddCustomPostForAccountPage'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./containers/FormPage'),
  loading: () => <Loading visible />,
});
