/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

// eslint-disable-next-line react/prefer-stateless-function
export default class carousel extends React.Component {
  render() {
    const settings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <Slider {...settings}>
        {this.props.galleryImages &&
          this.props.galleryImages.map(item => (
            <div
              key={item.id || item.imageUrl || item.videoUrl}
              className="media-preview"
            >
              {item.postType === 'photo' && (
                <img src={item.mediaUrl || item.imageUrl} alt="galleryImage" />
              )}
              {item.postType === 'video' && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  className="center"
                  type="video/mp4"
                  src={item.videoUrl || item.mediaUrl}
                  controls
                />
              )}
            </div>
          ))}
      </Slider>
    );
  }
}
