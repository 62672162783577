import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const Form = Loadable({
  loader: () => import('./containers/FormPage'),
  loading: () => <Loading visible />,
});

export const Posts = Loadable({
  loader: () => import('./containers/ListPage'),
  loading: () => <Loading visible />,
});

export const AddPost = Loadable({
  loader: () => import('./containers/AddPostPage'),
  loading: () => <Loading visible />,
});

export const AddCustomPost = Loadable({
  loader: () => import('./containers/AddCustomPostPage'),
  loading: () => <Loading visible />,
});
