import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'components';
import './Actions.scss';
import { withConfig } from 'infrastructure/config';
import { compose } from 'recompose';

const Actions = props => {
  const { handleSubmit, isSubmitting, isValid } = props;
  return (
    <div className="actions-component">
      <Button
        block
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit}
        color="success"
      >
        {<FontAwesomeIcon icon={faCheck} />} Save
      </Button>
    </div>
  );
};

Actions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  current: PropTypes.shape({}),
  config: PropTypes.shape({}),
};

Actions.defaultProps = {
  current: null,
  config: null,
};

const enhance = compose(withConfig);

export default enhance(Actions);
