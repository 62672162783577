import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './HeadlineFigure.scss';

function HeadlineFigure(props) {
  const { color, total, icon, showFractional, subHead, value } = props;
  return (
    <div className="headline-figure">
      <div className="top-line">
        <h3 className={classnames('total-stat', `text-${color}`)}>
          {new Intl.NumberFormat().format(value)}
          {total && showFractional && (
            <span className="denominator">
              /{new Intl.NumberFormat().format(total)}
            </span>
          )}
        </h3>
        <div className={classnames(`text-${color}`, 'stat-icon')}>{icon}</div>
      </div>
      {subHead && <small className="subhead">{subHead}</small>}
    </div>
  );
}

HeadlineFigure.propTypes = {
  color: PropTypes.string,
  total: PropTypes.number,
  icon: PropTypes.node,
  showFractional: PropTypes.bool,
  subHead: PropTypes.string,
  value: PropTypes.number.isRequired,
};

HeadlineFigure.defaultProps = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  total: null,
  icon: null,
  showFractional: false,
  subHead: null,
};

export default HeadlineFigure;
