import { composeValidators, isRequired, matchesPattern } from 'revalidate';
import moment from 'moment';

export const processValidate = (name, validate = [], { required, pattern }) => {
  if (!validate.length && !required && !pattern) {
    return null;
  }

  let validators = validate.splice(0);

  if (required) {
    validators = [...validators, isRequired];
  }

  if (pattern) {
    validators = [...validators, matchesPattern(new RegExp(pattern))];
  }

  return composeValidators(...validators)(name);
};

export const getValidity = (touched, error) => ({
  valid: !!(touched && !error),
  invalid: !!(touched && error),
});

export const notInPast = (field, value) => {
  let error;
  if (
    value &&
    moment.isMoment(value) &&
    value.isBefore(moment.utc().subtract({ hours: 7 }))
  ) {
    error = 'Schedule date must be in the future';
  }
  return error;
};
const noLink = values => !values.link || values.link === '';
const noLinkedFile = values => !values.linkedFile;
const formInvalid = values => noLink(values) && noLinkedFile(values);

export const validateForm = (values, props) => {
  let error;

  if (formInvalid(values, props)) {
    if (noLink(values)) {
      error = 'Please add a link or upload a file.';
    }
    if (noLinkedFile(values)) {
      error = 'Please add a link or upload a file.';
    }
  }
  return error;
};
