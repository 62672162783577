import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'components';
import { Col, Row } from 'reactstrap';
import { FormRow, DateTimeField } from 'components/Form';
import './Actions.scss';
import { withConfig } from 'infrastructure/config';
import { compose } from 'recompose';
import { notInPast } from 'infrastructure/form/validate';

const Actions = props => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    handleScheduleSubmit,
    isPublishDateValue,
    isScheduledPost,
    isNew,
  } = props;
  return (
    <React.Fragment>
      <div className="actions-component">
        <Button
          block
          disabled={
            isScheduledPost
              ? (!isValid && isNew) || isSubmitting
              : !isValid || isSubmitting
          }
          onClick={handleSubmit}
          color="success"
          loading={isSubmitting}
        >
          {<FontAwesomeIcon icon={faCheck} />}
          {isScheduledPost || isNew ? 'Publish Now' : 'Save'}
        </Button>
      </div>
      {isScheduledPost || isNew ? (
        <>
          <Row>
            <Col xs={12}>
              <FormRow label="Scheduling Time (PST)" borderless stacked>
                <DateTimeField
                  name="publishAt"
                  type="datetime"
                  withTimezone={false}
                  validate={[notInPast]}
                />
              </FormRow>
            </Col>
          </Row>
          <Button
            block
            className="btn-save-changes"
            color="primary"
            disabled={!isValid || isSubmitting || !isPublishDateValue}
            icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            iconAfter={false}
            loading={isSubmitting}
            onClick={handleScheduleSubmit}
          >
            Schedule
          </Button>
        </>
      ) : null}
    </React.Fragment>
  );
};

Actions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  current: PropTypes.shape({}),
  config: PropTypes.shape({}),
  handleScheduleSubmit: PropTypes.func.isRequired,
  isPublishDateValue: PropTypes.bool.isRequired,
  isScheduledPost: PropTypes.bool.isRequired,
};

Actions.defaultProps = {
  current: null,
  config: null,
};

const enhance = compose(withConfig);

export default enhance(Actions);
