import React from 'react';
import { Link } from 'react-router-dom';
import { Content, BlankSlate, Button } from 'components';

const Dashboard = () => (
  <Content title="Dashboard" className="dashboard">
    <BlankSlate
      title="Welcome to the LinkInBio CMS."
      message="Add, update, and delete links for your LinkInBio page"
      cta={
        <Button tag={Link} to="/accounts" color="primary">
          Get Started
        </Button>
      }
    />
  </Content>
);

export default Dashboard;
