import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Col, Input, Label, Row } from 'reactstrap';
import DropZone from 'components/Form/DropZone';
import PageToolbar from 'components/PageToolbar';
import FolderList from 'components/Assets/FolderList';
import { withForm } from 'infrastructure/form';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import './AssetBrowser.scss';

const EmptyMessage = withForm({})(() => (
  <Col>
    {/* Would be nice to have the blank slate in the dropzone but the component doesn't support it yet. */}
    <h2 className="h4 mb-4">There are no assets here yet.</h2>
    <Label for="asset" className="sr-only">
      Upload Assets
    </Label>
    <DropZone name="asset" />
  </Col>
));

const search = (
  <React.Fragment>
    <Label className="sr-only" htmlFor="assetSearch">
      Search
    </Label>
    <Input
      className="search-input input-icon-right"
      id="assetSearch"
      name="assetSearch"
      placeholder="eg. Folder or File Name"
      type="search"
    />
    <Button
      aria-label="Search Assets"
      type="button"
      className="input-group-append"
      icon={<FontAwesomeIcon icon={faSearch} />}
    />
  </React.Fragment>
);

const filters = (
  <Row>
    <Col xs={12} md className="filter">
      <Row className="align-items-center">
        <Col xs="auto">
          <Label htmlFor="sortBy">Sort By:</Label>
        </Col>
        <Col>
          <select
            className="form-control"
            id="sortBy"
            name="sortBy"
            openMenuOnFocus
          >
            <option value="1">Date Added</option>
            <option value="2">Date Modified</option>
            <option value="3">Name</option>
          </select>
        </Col>
      </Row>
    </Col>
    <Col xs={12} md className="filter">
      <ButtonGroup className="justified">
        <Button type="button" color="primary" size="sm">
          All
        </Button>
        <Button type="button" color="primary" outline size="sm">
          Folders
        </Button>
        <Button type="button" color="primary" outline size="sm">
          Images
        </Button>
        <Button type="button" color="primary" outline size="sm">
          Videos
        </Button>
      </ButtonGroup>
    </Col>
  </Row>
);

const defaultActions = (
  <Button
    type="button"
    icon={<FontAwesomeIcon icon={faPlus} />}
    color="primary"
    className="create-btn"
  >
    <span className="d-none d-lg-inline">New</span>
  </Button>
);

const AssetBrowser = ({ actions, children, folderList, showToolbar }) => (
  <React.Fragment>
    {showToolbar && (
      <PageToolbar search={search} filters={filters} actions={actions} />
    )}
    <Row>
      {folderList && (
        <Col md={4} lg={3}>
          <FolderList folders={folderList} />
        </Col>
      )}
      <Col md={folderList ? 8 : 12} lg={folderList ? 9 : 12}>
        <Row>{children}</Row>
      </Col>
    </Row>
  </React.Fragment>
);

AssetBrowser.propTypes = {
  children: PropTypes.node,
  folderList: PropTypes.arrayOf(PropTypes.shape({})),
  showToolbar: PropTypes.bool,
  actions: PropTypes.node,
};

AssetBrowser.defaultProps = {
  children: <EmptyMessage />,
  folderList: null,
  showToolbar: true,
  actions: defaultActions,
};

export default AssetBrowser;
