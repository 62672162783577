import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate, renderField } from 'infrastructure/form';

const TextField = props => {
  const { id, name, label, type, validate } = props;

  return (
    <Field
      {...props}
      component={renderField}
      id={id || name}
      name={name}
      type={type}
      validate={processValidate(label || startCase(name), validate, props)}
    />
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.shape({})),
};

TextField.defaultProps = {
  label: '',
  name: '',
  type: 'text',
  validate: [],
};

export default TextField;
