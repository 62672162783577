/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Label, Input, Row } from 'reactstrap';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import ToolbarActions from './ToolbarActions';

import './TableToolbar.scss';
/* eslint-enable no-unused-vars */

class MTableToolbar extends React.Component {
  stopSubmit = e => {
    e.preventDefault();
  };

  renderSearch() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };

    if (this.props.search) {
      return (
        <Col md={6} lg={4}>
          <form className="form" onSubmit={this.stopSubmit}>
            <div className="form__form-group-field">
              <Label className="sr-only" htmlFor="tableSearch">
                Search
              </Label>
              <Input
                className="seatch-input"
                id="tableSearch"
                name="tableSearch"
                placeholder={localization.searchTooltip}
                type="search"
                value={this.props.searchText}
                onChange={event =>
                  this.props.onSearchChanged(event.target.value)
                }
              />
              <Button
                aria-label="Search"
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
          </form>
        </Col>
      );
    } else {
      return null;
    }
  }

  renderDefaultActions() {
    return (
      <div>
        <this.props.components.ToolbarActions
          actions={
            this.props.actions && this.props.actions.filter(a => a.isFreeAction)
          }
        />
      </div>
    );
  }

  renderSelectedActions() {
    return (
      <React.Fragment>
        <this.props.components.ToolbarActions
          actions={this.props.actions.filter(a => !a.isFreeAction)}
          data={this.props.selectedRows}
        />
      </React.Fragment>
    );
  }

  renderActions() {
    return (
      <div>
        {this.props.selectedRows && this.props.selectedRows.length > 0
          ? this.renderSelectedActions()
          : this.renderDefaultActions()}
      </div>
    );
  }

  render() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...this.props.localization,
    };

    const { state } = this.props;

    const total = state.data.length;

    const pageSize = +(state.pageSize || 5);
    const start = pageSize * +(state.page || 0);
    let end = start + pageSize;
    if (end > total) {
      end = total;
    }

    const selectedRowsCount =
      this.props.selectedRows && this.props.selectedRows.length > 0
        ? localization.nRowsSelected.replace(
            '{0}',
            this.props.selectedRows.length
          )
        : this.props.title;

    const pagingSummary =
      total > 0
        ? localization.pagingSummary
            .replace('{0}', start + 1)
            .replace('{1}', end)
            .replace('{2}', total)
        : '';

    return (
      <Row className="table-toolbar align-items-center">
        {this.renderSearch()}
        {pagingSummary && (
          <Col xs="auto">
            <h5>{pagingSummary}</h5>
          </Col>
        )}
        <Col xs={12} className="col-lg">
          <Row className="align-items-center justify-content-end">
            {selectedRowsCount && (
              <Col xs="auto">
                <h5>{selectedRowsCount}</h5>
              </Col>
            )}
            <Col xs="auto">{this.renderActions()}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

MTableToolbar.defaultProps = {
  actions: [],
  columns: [],
  columnsButton: false,
  onSearchChanged: () => {},
  onColumnsChanged: () => {},
  localization: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export as CSV',
    searchTooltip: 'Search',
    pagingSummary: 'Showing {0} to {1} of {2}.',
  },
  search: true,
  searchText: '',
  selectedRows: [],
  title: '',
  components: {
    ToolbarActions,
  },
};

MTableToolbar.propTypes = {
  actions: PropTypes.array,
  localization: PropTypes.object.isRequired,
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.bool.isRequired,
  searchText: PropTypes.string.isRequired,
  selectedRows: PropTypes.array,
  title: PropTypes.string.isRequired,
  state: PropTypes.shape({}).isRequired,
};

export default MTableToolbar;
/* eslint-enable react/default-props-match-prop-types */
/* eslint-enable react/require-default-props */
/* eslint-enable react/forbid-prop-types */
/* eslint-enable react/no-array-index-key */
/* eslint-enable react/destructuring-assignment */
/* eslint-enable prefer-template */
/* eslint-enable no-else-return */
