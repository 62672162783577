import React from 'react';
import { Route, Switch } from 'react-router-dom';
// Redirect
import { Secured } from 'infrastructure/security';
import { ErrorBoundary, ErrorPage } from 'infrastructure/error';

import Dashboard from 'containers/Dashboard/index';
import Layout from 'containers/Layout';
import LogIn from 'slices/Account/Loadable';
import MainWrapper from 'containers/App/MainWrapper';
// import IdentityUser from 'slices/IdentityUser/Loadable';
// import IdentityRole from 'slices/IdentityRole/Loadable';
import Accounts from 'slices/Accounts/Loadable';
import InstagramPosts from 'slices/Posts/Loadable';
import Category from 'slices/Category/Loadable';

const wrappedRoutes = () => (
  <Secured>
    <React.Fragment>
      <Layout />
      <div className="container__wrap">
        <ErrorBoundary>
          <Route path="/" component={Dashboard} exact />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/accounts" component={Accounts} />
          <Route path="/posts" component={InstagramPosts} />
          <Route path="/category" component={Category} />
          {/* <Route
            path="/identity"
            render={() => <Redirect to="/identity/user" />}
            exact
          />
          <Route path="/identity/role" component={IdentityRole} />
          <Route path="/identity/user" component={IdentityUser} /> */}
        </ErrorBoundary>
      </div>
    </React.Fragment>
  </Secured>
);

const AppRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default AppRouter;
