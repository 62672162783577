import React from 'react';
import Select from 'react-select';
import { Col, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import Button from 'components/Button';
import Scheduling from 'components/Scheduling';
import Fieldset from 'components/Form/Fieldset';
import FormRow from 'components/Form/FormRow';
import TextField from 'components/Form/TextField';
import AdSummary from 'components/Stories/AdSummary';

import './PageSettings.scss';
import variables from 'scss/1-settings/colors.scss';

const reactSelectTheme = theme => ({
  ...theme,
  borderRadius: '0.2rem',
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const PageSettings = () => (
  <form>
    <Fieldset legend="Page Settings">
      <Row>
        <Col md={6}>
          <FormRow label="Title" stacked borderless>
            <TextField name="title" placeholder="Title" required />
          </FormRow>
        </Col>
        <Col md={6}>
          <FormRow
            borderless
            className="mb-3"
            helpText="(seconds)"
            label="Duration"
            stacked
          >
            <TextField
              name="durartion"
              placeholder="Duration"
              required
              value={10}
            />
          </FormRow>
        </Col>
      </Row>
      <Scheduling />
    </Fieldset>
    <Fieldset legend="Sposorship" className="padding-top">
      <Row>
        <Col xs={12} md={6}>
          <form className="form">
            <div className="form__form-group-field">
              <Input
                className="search-input input-icon-right"
                name="tableSearch"
                placeholder="eg. Folder or File Name"
                type="search"
              />
              <Button
                type="button"
                className="input-group-append"
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
          </form>
        </Col>
        <Col xs={12} md={3}>
          <Select
            classNamePrefix="rs"
            defaultValue={1}
            name="sortBy"
            openMenuOnFocus
            options={[
              { label: 'Date Added', value: 1 },
              { label: 'Date Modified', value: 2 },
              { label: 'Name', value: 3 },
            ]}
            theme={reactSelectTheme}
            value={1}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <AdSummary
            imgSrc="https://placedog.net/128/128"
            selectable
            sponsor="sponsor name"
            title="Title of the ad"
          />
        </Col>
        <Col md={6}>
          <AdSummary
            imgSrc="https://placedog.net/128/128"
            selectable
            sponsor="sponsor name"
            title="Title of the ad"
          />
        </Col>
        <Col md={6}>
          <AdSummary
            imgSrc="https://placedog.net/128/128"
            selectable
            sponsor="sponsor name"
            title="Title of the ad"
          />
        </Col>
        <Col md={6}>
          <AdSummary
            imgSrc="https://placedog.net/128/128"
            selectable
            sponsor="sponsor name"
            title="Title of the ad"
          />
        </Col>
        <Col md={6}>
          <AdSummary
            imgSrc="https://placedog.net/128/128"
            selectable
            sponsor="sponsor name"
            title="Title of the ad"
          />
        </Col>
        <Col md={6}>
          <AdSummary
            imgSrc="https://placedog.net/128/128"
            selectable
            sponsor="sponsor name"
            title="Title of the ad"
          />
        </Col>
      </Row>
    </Fieldset>
  </form>
);

PageSettings.propTypes = {};

export default PageSettings;
