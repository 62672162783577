import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { visibility } from 'shared/enhancers';
import './Title.scss';

const Title = ({ title, children }) => (
  <h1 className="page-title">{title || children}</h1>
);

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
Title.defaultProps = {
  title: null,
  children: null,
};

const enhance = compose(
  withProps(props => ({
    visible:
      ((props.title || props.children) &&
        (props.visible === undefined || props.visible === null)) ||
      props.visible,
  })),
  visibility,
  React.memo
);

export default enhance(Title);
