import React from 'react';
import { Row, Col } from 'reactstrap';
import { Button, Scheduling } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import './Actions.scss';

const Actions = props => {
  const { handleReset, isSubmitting, isValid, showSchedule } = props;

  return (
    <div className="actions-component">
      <div className="action-group">
        <Button
          block
          disabled={isSubmitting}
          loading={isSubmitting}
          color="success"
          type="submit"
          icon={<FontAwesomeIcon icon={faCheck} />}
        >
          Publish
        </Button>
      </div>
      {showSchedule && <Scheduling />}
      <Row className="align-items-center">
        <Col>
          <Button
            block
            disabled={!isValid || isSubmitting}
            onClick={handleReset}
            color="danger"
            type="button"
            icon={<FontAwesomeIcon icon={faTimes} />}
            outline
          >
            Discard Changes
          </Button>
        </Col>
        <Col>
          <Button
            block
            disabled={isSubmitting}
            color="primary"
            type="button"
            icon={<FontAwesomeIcon icon={faSave} />}
          >
            Save Draft
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Actions.propTypes = {
  handleReset: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showSchedule: PropTypes.bool,
};

Actions.defaultProps = {
  showSchedule: false,
};

export default Actions;
