import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'components/Button';
import Modal from 'components/Modal';
import AddPage from 'components/Stories/AddPage';
import PageSettings from 'components/Stories/PageSettings';
import PageThumbnail from 'components/Stories/PageThumbnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Fieldset } from 'components/Form';
import './StoryEditor.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const onDragEnd = (onChange, options) => result => {
  if (!result.destination) return;

  const items = reorder(options, result.source.index, result.destination.index);
  onChange(items);
};

const StoryEditor = ({ onChange, options }) => (
  <Fieldset legend="Story Editor" className="story-editor">
    <DragDropContext onDragEnd={onDragEnd(onChange, options)}>
      <Droppable droppableId="pageOrder" direction="horizontal">
        {provided => (
          <div
            className="row no-gutters story-book align-items-center"
            ref={provided.innerRef}
          >
            <div className="page">
              <Modal
                buttonProps={{
                  block: true,
                  className: 'btn-icon btn-add-page rounded',
                  color: 'light',
                  icon: <FontAwesomeIcon icon={faPlus} />,
                }}
              >
                <AddPage />
              </Modal>
            </div>
            {options.map((option, index) => (
              <Draggable
                key={option.value}
                draggableId={option.value}
                index={index}
              >
                {(p, s) => (
                  <div
                    className={classnames('page', {
                      active: s.isDragging,
                    })}
                    ref={p.innerRef}
                    {...p.draggableProps}
                    {...p.dragHandleProps}
                  >
                    <PageThumbnail
                      actions={
                        <React.Fragment>
                          <Modal
                            buttonProps={{
                              color: 'success',
                              icon: <span className="lnr lnr-pencil" />,
                            }}
                          >
                            <PageSettings />
                          </Modal>
                          <Button
                            color="danger"
                            icon={<span className="lnr lnr-circle-minus" />}
                          />
                        </React.Fragment>
                      }
                      alt={option.name}
                      imgSrc={option.thumbnail}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            <div className="page">
              <Modal
                buttonProps={{
                  block: true,
                  className: 'btn-icon btn-add-page rounded',
                  color: 'light',
                  icon: <FontAwesomeIcon icon={faPlus} />,
                }}
              >
                <AddPage />
              </Modal>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
    <p className="help-text mt-3">Drag the pages to re-arrange</p>
  </Fieldset>
);

const changeHandler = () => 'onchange';

StoryEditor.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
};

StoryEditor.defaultProps = {
  options: [],
  onChange: changeHandler,
};

export default StoryEditor;
