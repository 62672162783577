import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import { Img } from 'components';
import './AdSummary.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

class AdSummary extends Component {
  constructor(props) {
    super(props);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.state = { selected: false };
  }

  toggleSelect() {
    this.setState(state => ({
      selected: !state.selected,
    }));
    document.activeElement.blur();
  }

  render() {
    const { imgSrc, selectable, sponsor, title } = this.props;
    const { selected } = this.state;
    return (
      <Row
        tag={selectable ? 'button' : 'div'}
        type={selectable ? 'button' : null}
        className={classnames(
          { selected, selectable },
          'ad-summary no-gutters align-items-center'
        )}
        onClick={selectable && this.toggleSelect}
      >
        <Col className="thumbnail" xs="auto">
          <Img src={imgSrc} alt={title} />
        </Col>
        <Col>
          <div className="ad-info">
            {sponsor && <small className="ad-sponsor">{sponsor}</small>}
            {title && <h4 className="ad-title">{title}</h4>}
            {selectable && (
              <FontAwesomeIcon icon={faPlus} className="selected-checkmark" />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

AdSummary.propTypes = {
  imgSrc: PropTypes.string,
  selectable: PropTypes.bool,
  sponsor: PropTypes.string,
  title: PropTypes.string,
};

AdSummary.defaultProps = {
  imgSrc: '',
  selectable: false,
  sponsor: '',
  title: '',
};

export default AdSummary;
