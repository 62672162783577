import React from 'react';
import { Button as Btn } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Button.scss';

const Button = ({
  block,
  buttonStyle,
  children,
  className,
  color,
  disabled,
  icon,
  iconAfter,
  loading,
  ...props
}) => {
  const bootstrapClasses = {
    link: `btn-link text-${color}`,
  };

  const loadingIndicatorVariant = {
    link: 'loading-indicator-link',
    outline: 'loading-indicator-outline',
    solid: 'loading-indicator-solid',
  }[buttonStyle];

  return (
    <Btn
      className={classnames(
        bootstrapClasses[buttonStyle],
        { 'btn-block': block },
        className
      )}
      color={buttonStyle === 'link' ? 'link' : color}
      disabled={disabled || loading}
      outline={buttonStyle === 'outline'}
      {...props}
    >
      {!iconAfter && icon} {children} {iconAfter && icon}
      {loading && (
        <div
          className={classnames(loadingIndicatorVariant, 'loading-indicator')}
        >
          <div className="ellipsis-container">
            <div className="ellipsis-1" />
            <div className="ellipsis-2" />
            <div className="ellipsis-3" />
            <div className="ellipsis-4" />
          </div>
        </div>
      )}
    </Btn>
  );
};

Button.propTypes = {
  block: PropTypes.bool,
  buttonStyle: PropTypes.oneOf(['link', 'outline', 'solid']), // btn-link is a Bootstrap class that's broken by EasyDev
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconAfter: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['', 'sm', 'lg']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  block: false,
  buttonStyle: 'solid',
  children: null,
  className: '',
  color: null,
  disabled: false,
  icon: null,
  iconAfter: true,
  loading: false,
  size: '',
  type: 'button',
};

export default Button;
