import React from 'react';
import { ButtonGroup } from 'reactstrap';
import { Img } from 'components';
import './PageThumbnail.scss';
import PropTypes from 'prop-types';

const PageThumbnail = props => {
  const { actions, imgSrc, name } = props;
  return (
    <div className="page-thumbnail">
      <div className="thumbnail-container rounded container-aspect-ratio container-9x16">
        <Img alt={name} src={imgSrc} />
        {actions && (
          <div className="actions-overlay">
            <ButtonGroup className="actions icons">{actions}</ButtonGroup>
          </div>
        )}
      </div>
      {name && <small className="page-name">{name}</small>}
    </div>
  );
};

PageThumbnail.propTypes = {
  actions: PropTypes.node,
  imgSrc: PropTypes.string,
  name: PropTypes.string,
};

PageThumbnail.defaultProps = {
  actions: null,
  imgSrc: '',
  name: '',
};

export default PageThumbnail;
