import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'rc-notification';
// import { BasicNotification } from 'shared/components/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faThumbsUp,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import './Toast.scss';

const close = () => (
  <React.Fragment>
    <button className="close" type="button">
      <span className="lnr lnr-cross" />
    </button>
  </React.Fragment>
);

let notification = null;

Notification.newInstance(
  {
    closeIcon: close(),
  },
  n => {
    notification = n;
  }
);

const sharedOptions = {
  duration: 7,
  closable: true,
  style: {},
  maxCount: 5,
};

const successOptions = {
  className: 'alert alert--colored right alert-success',
};

const errorOptions = {
  className: 'alert alert--colored right alert-danger',
};

const icon = {
  info: <FontAwesomeIcon icon={faInfoCircle} />,
  success: <FontAwesomeIcon icon={faThumbsUp} />,
  warning: <FontAwesomeIcon icon={faExclamationCircle} />,
  danger: <FontAwesomeIcon icon={faExclamationTriangle} />,
};

const AlertContent = ({ msg, title, color }) => (
  <React.Fragment>
    <div className="alert__icon">{icon[color]}</div>
    <div className="alert__content">
      <h5 className="bold-text">{title}</h5>
      <p>{msg}</p>
    </div>
  </React.Fragment>
);

AlertContent.propTypes = {
  msg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export { AlertContent }; // For storybook

export default {
  success: (msg, title = 'Success!') =>
    notification.notice({
      content: (
        <AlertContent color="success" msg={msg} title={title} icon={icon} />
      ),
      ...sharedOptions,
      ...successOptions,
    }),
  error: (msg, title = 'Error!') =>
    notification.notice({
      content: (
        <AlertContent color="danger" msg={msg} title={title} icon={icon} />
      ),
      ...sharedOptions,
      ...errorOptions,
    }),
};
