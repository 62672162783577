import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { loading } from 'shared/enhancers';
import { Loading } from 'components';

import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const enhance = loading(
  x => !x.menu || !x.menu.items.length,
  () => <Loading visible />
);

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    menu: PropTypes.shape(),
  };

  static defaultProps = {
    menu: {
      items: [],
    },
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  renderItems = items =>
    items.map(item => {
      let LinkComponent = SidebarLink;
      if (!item.isLeaf) {
        LinkComponent = SidebarCategory;
      }

      return (
        <LinkComponent
          title={item.displayName}
          route={item.url}
          className={item.cssClass}
          key={item.elementId}
          icon={item.icon}
          onClick={this.hideSidebar}
        >
          <ul>{this.renderItems(item.items)}</ul>
        </LinkComponent>
      );
    });

  render() {
    const { menu } = this.props;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">{this.renderItems(menu.items)}</ul>
      </div>
    );
  }
}

export default enhance(SidebarContent);
