import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './AccountSelectButton.scss';

class AccountSelectButton extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { selected: false };
  }

  toggle() {
    const { onClick } = this.props;
    onClick();
    this.setState(state => ({ selected: !state.selected }));
    document.activeElement.blur();
  }

  render() {
    const { selected } = this.state;
    const { account } = this.props;
    return (
      <button
        disabled={account.disabled}
        className={classnames(
          'unstyled-button account-select-button rounded mb-4',
          {
            selected,
          }
        )}
        onClick={this.toggle}
        type="button"
      >
        <img
          src={account.profileImageUrl}
          alt={account.label}
          className="rounded-circle profile-pic"
        />
        <h4>{account.label || account.title}</h4>
      </button>
    );
  }
}

AccountSelectButton.propTypes = {
  account: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AccountSelectButton;
