import { withFormik } from 'formik';
import { compose, withHandlers } from 'recompose';

const state = {
  Draft: () => true,
  Published: props => (props.dirty && props.isValid) || true,
};

export default opts =>
  compose(
    withFormik({
      handleSubmit: (data, { props, ...form }) => props.onSubmit(data, form),
      ...opts,
    }),
    withHandlers({
      handleChangeStatus: props => status => {
        if (state[status] && state[status](props)) {
          props.setFieldValue('status', status);
          props.setStatus({
            ...props.status,
            changingPublishStatus: true,
          });

          props.submitForm();
        }
      },
      handleDelete: ({ api, onDeleted = () => {} }) => {
        if (!api || !api.delete) return null;

        return id => {
          api.delete(id);
          onDeleted();
        };
      },
    })
  );
