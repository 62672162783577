import React from 'react';
import { Tabs } from 'components';
import Actions from './Actions';
import './FormActions.scss';
// import PropTypes from 'prop-types';

const FormActions = props => (
  <React.Fragment>
    <Tabs
      border="bottom"
      className="form-actions fieldset"
      content={[{ tabName: 'Actions', tabContent: <Actions {...props} /> }]}
      justify={false}
    />
  </React.Fragment>
);

FormActions.propTypes = {};

export default FormActions;
