import React from 'react';
import PropTypes from 'prop-types';
import './StoryPreview.scss';
import Loading from 'components/Loading';
import PeviewFrame from './story-preview-frame.png';

const StoryPreview = props => {
  const { bottomOverlay, isLoading, previewImage, topOverlay } = props;
  return (
    <div className="story-preview">
      {isLoading && <Loading visible />}
      <img src={PeviewFrame} alt="" className="frame" />
      <div className="preview-container">
        {previewImage && (
          <img src={previewImage} alt="Story Preview" className="preview" />
        )}
        {topOverlay && (
          <img src={topOverlay} alt="Top overlay" className="overlay top" />
        )}
        {bottomOverlay && (
          <img
            src={bottomOverlay}
            alt="Bottom Overlay"
            className="overlay bottom"
          />
        )}
      </div>
    </div>
  );
};

StoryPreview.propTypes = {
  bottomOverlay: PropTypes.string,
  isLoading: PropTypes.bool,
  previewImage: PropTypes.string,
  topOverlay: PropTypes.string,
};

StoryPreview.defaultProps = {
  bottomOverlay: '',
  isLoading: false,
  previewImage: '',
  topOverlay: '',
};

export default StoryPreview;
