import React from 'react';
import PropTypes from 'prop-types';
import { Button, Carousel, Img } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderAll } from '@fortawesome/pro-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { withConfig } from 'infrastructure/config';
import { compose } from 'recompose';
import './PostPreview.scss';

const PostPreview = props => {
  const { current, config } = props;
  return (
    <div className="post-preview rounded">
      <div className="post-container">
        {!current.isGallery && current.postType === 'video' && (
          <React.Fragment>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video type="video/mp4" src={current.videoUrl} controls />
          </React.Fragment>
        )}
        {current.isGallery === true && (
          <React.Fragment>
            <Carousel galleryImages={current.assets || []} />
          </React.Fragment>
        )}
        {!current.isGallery && current.postType === 'photo' && (
          <React.Fragment>
            <Img src={current.imageUrl} />
          </React.Fragment>
        )}
      </div>
      <div className="post-actions">
        {current.permaLink && (
          <Button
            aria-label="View on Instagram"
            className="btn-icon rounded-circle view-on-instagram"
            size="sm"
            href={current.permaLink}
            target="_blank"
            color="info"
            title="View on Instagram"
          >
            {<FontAwesomeIcon icon={faInstagram} size="lg" />}
          </Button>
        )}
        {current.accountUrlName && (
          <Button
            aria-label="View on Link In Bio"
            className="btn-icon rounded-circle view-on-lib"
            color="secondary"
            // to do: add url to config file
            href={`${config.frontendUrl}/${current.accountUrlName}/post/${current.id}`}
            size="sm"
            target="_blank"
            title="View on Link In Bio"
          >
            <FontAwesomeIcon icon={faBorderAll} />
          </Button>
        )}
      </div>
    </div>
  );
};

PostPreview.propTypes = {
  current: PropTypes.shape({}).isRequired,
  config: PropTypes.shape({}).isRequired,
};

const enhance = compose(withConfig);

export default enhance(PostPreview);
