import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { visibility } from 'shared/enhancers';

const SubHeading = ({ subHeading, children }) => (
  <p className="page-subhead subhead">{subHeading || children}</p>
);

SubHeading.propTypes = {
  subHeading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
SubHeading.defaultProps = {
  subHeading: null,
  children: null,
};

const enhance = compose(
  withProps(props => ({
    visible:
      ((props.subHeading || props.children) &&
        (props.visible === undefined || props.visible === null)) ||
      props.visible,
  })),
  visibility,
  React.memo
);

export default enhance(SubHeading);
