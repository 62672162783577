import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import Select from 'react-select';
import { FormFeedback } from 'reactstrap';
import { startCase } from 'lodash';
import classnames from 'classnames';
import { processValidate, getValidity } from 'infrastructure/form';
import variables from 'scss/1-settings/colors.scss';
import './SelectField.scss';

const reactSelectTheme = theme => ({
  ...theme,
  borderRadius: '0.2rem',
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const findValue = (options, field, isMulti, defaultValue) => {
  if (!field || !field.value) {
    if (!defaultValue) return null;

    return (options || []).find(x => x.value === defaultValue);
  }

  if (!isMulti) {
    return (options || []).find(x => x.value === field.value);
  }

  return (options || []).filter(x => field.value.indexOf(x.value) > -1);
};

const renderSelect = ({
  form,
  field,
  options,
  isMulti,
  onChange,
  defaultValue,
  ...props
}) => {
  const { touched = {}, errors = {} } = form;
  const validity = getValidity(
    getIn(touched, field.name),
    getIn(errors, field.name)
  );

  return (
    <div className="form__form-group-input-wrap--error-below">
      <Select
        {...props}
        classNamePrefix="rs"
        defaultValue={findValue(options, field, isMulti, defaultValue)}
        isMulti={isMulti}
        theme={reactSelectTheme}
        className={classnames({
          'is-invalid': validity.invalid,
          'is-valid': validity.valid,
        })}
        onChange={option => {
          const value = isMulti ? option.map(x => x.value) : option.value;
          form.setFieldValue(field.name, value);
          form.setFieldTouched(field.name, true);
          onChange(value);
        }}
        onBlur={() => form.setFieldTouched(field.name, true)}
        options={options}
        value={findValue(options, field, isMulti, defaultValue)}
        openMenuOnFocus
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <FormFeedback className="validation form__form-group-error" tag="small">
          {getIn(errors, field.name)}
        </FormFeedback>
      )}
    </div>
  );
};

const SelectField = props => {
  const { validate, label, name } = props;

  return (
    <Field
      {...props}
      component={renderSelect}
      name={name}
      validate={processValidate(label || startCase(name), validate, props)}
    />
  );
};
SelectField.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
SelectField.defaultProps = {
  validate: [],
  label: '',
  onChange: () => {},
};

export default SelectField;
