import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Img } from 'components';
import './ImageSelect.scss';

class ImageSelect extends Component {
  constructor(props) {
    const { defaultSelected } = props;
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { selected: defaultSelected };
  }

  toggle() {
    const { onClick } = this.props;
    onClick();
    this.setState(state => ({ selected: !state.selected }));
    document.activeElement.blur();
  }

  render() {
    const { selected } = this.state;
    const { caption, className, name, src } = this.props;
    return (
      <button
        aria-label={`Select ${name}`}
        className={classnames('image-select unstyled-button w-100', className, {
          selected,
        })}
        color="primary"
        onClick={this.toggle}
        title={`Select ${name}`}
        type="button"
      >
        <div className="container-aspect-ratio container-1x1 rounded w-100">
          <Img src={src} alt={name} containerClass="contain-image" blurFill />
        </div>
        {caption && <div className="caption">{caption.substring(0, 72)}</div>}
      </button>
    );
  }
}

ImageSelect.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  defaultSelected: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
};

ImageSelect.defaultProps = {
  caption: '',
  className: '',
  defaultSelected: false,
  name: 'Image',
  onClick: () => {},
};

export default ImageSelect;
