import {
  faTrashAlt,
  faCamera,
  faBorderAll,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const buildActions = (handlers, props) => [
  () => {
    if (handlers.delete && handlers.supportsDelete) {
      return {
        tooltip: 'Delete',
        color: 'danger',
        type: 'button',
        icon: faTrashAlt,
        buttonStyle: 'outline',
        isRowAction: true,
        isSelectedAction: true,
        onClick: (_, item) => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Are you sure you want to delete this item?'))
            handlers.delete(item, props);
        },
        toolbarIcon: faTrashAlt,
        toolbarClassName: 'btn-delete',
        toolbarButtonPriority: 'danger',
      };
    }

    return null;
  },
  {
    tooltip: 'Edit',
    color: 'success',
    icon: faPencil,
    isRowAction: true,
    onClick: (_, item) => handlers.edit(item, props),
  },
  {
    tooltip: 'Instagram',
    color: 'info',
    type: 'button',
    icon: faInstagram,
    buttonStyle: 'outline',
    isRowAction: true,
    onClick: (_, item) => {
      window.open(`https://www.instagram.com/${item.urlName}/`);
      document.activeElement.blur();
    },
  },
  {
    tooltip: 'LinkInBio',
    color: 'info',
    type: 'button',
    icon: faBorderAll,
    buttonStyle: 'outline',
    isRowAction: true,
    onClick: (_, item) => {
      window.open(`${props.config.frontendUrl}/${item.urlName}/`);
      document.activeElement.blur();
    },
  },
  {
    tooltip: 'Posts ',
    color: 'success',
    type: 'button',
    buttonStyle: 'outline',
    icon: faCamera,
    isRowAction: true,
    onClick: (_, item) => handlers.viewPosts(item, props),
  },
  {
    customAction: true,
    isFreeAction: true,
    render: () => handlers.custom(props),
  },
];

export default (handlers, props) =>
  buildActions(
    {
      openExternal: item => {
        window.open(item.url);
        document.activeElement.blur();
      },
      ...handlers,
    },
    props
  );
