import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { getValidity } from './validate';

const renderField = ({
  field,
  form: { touched = {}, errors = {} },
  ...props
}) => (
  <div className="form__form-group-input-wrap--error-below">
    <Input
      {...props}
      {...field}
      {...getValidity(getIn(touched, field.name), getIn(errors, field.name))}
    />
    {getIn(touched, field.name) && getIn(errors, field.name) && (
      <FormFeedback className="validation form__form-group-error" tag="small">
        {getIn(errors, field.name)}
      </FormFeedback>
    )}
  </div>
);

renderField.propTypes = {
  field: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

export default renderField;
