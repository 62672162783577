import React from 'react';
import { Breadcrumb as BreadcrumbContainer } from 'reactstrap';
import PropTypes from 'prop-types';

import Breadcrumb from './Breadcrumb';

const Breadcrumbs = props => {
  const { path } = props;
  return (
    <BreadcrumbContainer>
      {path
        .filter(x => !x.isCurrent)
        .map(item => (
          <Breadcrumb key={item.url} title={item.displayName} to={item.url} />
        ))}
    </BreadcrumbContainer>
  );
};
Breadcrumbs.defaultProps = {
  path: [],
};

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Breadcrumbs;
