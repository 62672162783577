import React from 'react';
import './Activity.scss';
import PropTypes from 'prop-types';

const Activity = props => {
  const { action, imgSrc, moreInfo, name, timestamp } = props;
  return (
    <div className="activity">
      {imgSrc && (
        <img src={imgSrc} alt={name} className="thumbnail rounded-pill" />
      )}
      <div className="details">
        {action && <small className="action">{action}:</small>}
        {name && <h4 className="name">{name}</h4>}
        {timestamp && <span className="timestamp">{timestamp}</span>}
        {moreInfo && <p className="more-info">{moreInfo}</p>}
      </div>
    </div>
  );
};

Activity.propTypes = {
  action: PropTypes.string,
  imgSrc: PropTypes.string,
  moreInfo: PropTypes.string,
  name: PropTypes.string,
  timestamp: PropTypes.string,
};

Activity.defaultProps = {
  action: '',
  imgSrc: '',
  moreInfo: '',
  name: '',
  timestamp: '',
};

export default Activity;
